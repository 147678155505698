<template>
  <b-container v-if="this.virtualPhones">
    <virtual-phone-setup-modal />
    <step-0 />
  </b-container>
</template>

<script>
/*
Dev Notes:
This component can be added anywhere that we might need to capture data that is out of the ordinary.
The component lies in wait on the page, and opens a modal if it sees something missing on the
account. This is intended for collecting information that doesn't fit with our normal schema/ORA/SRA
process (e.g. Bandwidth API Phone configuration and any complex items we add in the future).
*/


import VirtualPhoneSetupModal from '@/components/VirtualPhones/SetupModal/VirtualPhoneSetupModal.vue'
import Step0 from '@/components/VirtualPhones/SetupModal/Step0.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CaptureOddData',
  components: { VirtualPhoneSetupModal, Step0 },

  watch: {
    async virtualPhones() {
      await this.captureDataForMissingNumbers()
    },
  },

  async mounted() {
    await this.captureDataForMissingNumbers()
  },

  computed: {
    ...mapGetters('virtualPhones', ['virtualPhones']),
  },

  methods: {
    ...mapActions('virtualPhones', ['triggerConfigModalIfNeeded', 'setVirtualPhonesToConfigure', 'loadVirtualPhones']),

    async captureDataForMissingNumbers() {
      await this.setVirtualPhonesToConfigure()
      await this.triggerConfigModalIfNeeded(this)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
