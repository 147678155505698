export default [
  {
    "code": "201",
    "description": "New Jersey",
  },
  {
    "code": "202",
    "description": "District of Columbia",
  },
  {
    "code": "203",
    "description": "Connecticut",
  },
  {
    "code": "205",
    "description": "Alabama",
  },
  {
    "code": "206",
    "description": "Washington",
  },
  {
    "code": "207",
    "description": "Maine",
  },
  {
    "code": "208",
    "description": "Idaho",
  },
  {
    "code": "209",
    "description": "California",
  },
  {
    "code": "210",
    "description": "Texas",
  },
  {
    "code": "212",
    "description": "New York",
  },
  {
    "code": "213",
    "description": "California",
  },
  {
    "code": "214",
    "description": "Texas",
  },
  {
    "code": "215",
    "description": "Pennsylvania",
  },
  {
    "code": "216",
    "description": "Ohio",
  },
  {
    "code": "217",
    "description": "Illinois",
  },
  {
    "code": "218",
    "description": "Minnesota",
  },
  {
    "code": "219",
    "description": "Indiana",
  },
  {
    "code": "224",
    "description": "Illinois",
  },
  {
    "code": "225",
    "description": "Louisiana",
  },
  {
    "code": "228",
    "description": "Mississippi",
  },
  {
    "code": "229",
    "description": "Georgia",
  },
  {
    "code": "231",
    "description": "Michigan",
  },
  {
    "code": "234",
    "description": "Ohio",
  },
  {
    "code": "239",
    "description": "Florida",
  },
  {
    "code": "240",
    "description": "Maryland",
  },
  {
    "code": "248",
    "description": "Michigan",
  },
  {
    "code": "251",
    "description": "Alabama",
  },
  {
    "code": "252",
    "description": "North Carolina",
  },
  {
    "code": "253",
    "description": "Washington",
  },
  {
    "code": "254",
    "description": "Texas",
  },
  {
    "code": "256",
    "description": "Alabama",
  },
  {
    "code": "260",
    "description": "Indiana",
  },
  {
    "code": "262",
    "description": "Wisconsin",
  },
  {
    "code": "267",
    "description": "Pennsylvania",
  },
  {
    "code": "269",
    "description": "Michigan",
  },
  {
    "code": "270",
    "description": "Kentucky",
  },
  {
    "code": "276",
    "description": "Virginia",
  },
  {
    "code": "278",
    "description": "Michigan",
  },
  {
    "code": "281",
    "description": "Texas",
  },
  {
    "code": "283",
    "description": "Ohio",
  },
  {
    "code": "301",
    "description": "Maryland",
  },
  {
    "code": "302",
    "description": "Delaware",
  },
  {
    "code": "303",
    "description": "Colorado",
  },
  {
    "code": "304",
    "description": "West Virginia",
  },
  {
    "code": "305",
    "description": "Florida",
  },
  {
    "code": "307",
    "description": "Wyoming",
  },
  {
    "code": "308",
    "description": "Nebraska",
  },
  {
    "code": "309",
    "description": "Illinois",
  },
  {
    "code": "310",
    "description": "California",
  },
  {
    "code": "312",
    "description": "Illinois",
  },
  {
    "code": "313",
    "description": "Michigan",
  },
  {
    "code": "314",
    "description": "Missouri",
  },
  {
    "code": "315",
    "description": "New York",
  },
  {
    "code": "316",
    "description": "Kansas",
  },
  {
    "code": "317",
    "description": "Indiana",
  },
  {
    "code": "318",
    "description": "Louisiana",
  },
  {
    "code": "319",
    "description": "Iowa",
  },
  {
    "code": "320",
    "description": "Minnesota",
  },
  {
    "code": "321",
    "description": "Florida",
  },
  {
    "code": "323",
    "description": "California",
  },
  {
    "code": "325",
    "description": "Texas",
  },
  {
    "code": "330",
    "description": "Ohio",
  },
  {
    "code": "331",
    "description": "Illinois",
  },
  {
    "code": "334",
    "description": "Alabama",
  },
  {
    "code": "336",
    "description": "North Carolina",
  },
  {
    "code": "337",
    "description": "Louisiana",
  },
  {
    "code": "339",
    "description": "Massachusetts",
  },
  {
    "code": "340",
    "description": "US Virgin Islands",
  },
  {
    "code": "341",
    "description": "California",
  },
  {
    "code": "347",
    "description": "New York",
  },
  {
    "code": "351",
    "description": "Massachusetts",
  },
  {
    "code": "352",
    "description": "Florida",
  },
  {
    "code": "360",
    "description": "Washington",
  },
  {
    "code": "361",
    "description": "Texas",
  },
  {
    "code": "369",
    "description": "California",
  },
  {
    "code": "380",
    "description": "Ohio",
  },
  {
    "code": "385",
    "description": "Utah",
  },
  {
    "code": "386",
    "description": "Florida",
  },
  {
    "code": "401",
    "description": "Rhode Island",
  },
  {
    "code": "402",
    "description": "Nebraska",
  },
  {
    "code": "404",
    "description": "Georgia",
  },
  {
    "code": "405",
    "description": "Oklahoma",
  },
  {
    "code": "406",
    "description": "Montana",
  },
  {
    "code": "407",
    "description": "Florida",
  },
  {
    "code": "408",
    "description": "California",
  },
  {
    "code": "409",
    "description": "Texas",
  },
  {
    "code": "410",
    "description": "Maryland",
  },
  {
    "code": "412",
    "description": "Pennsylvania",
  },
  {
    "code": "413",
    "description": "Massachusetts",
  },
  {
    "code": "414",
    "description": "Wisconsin",
  },
  {
    "code": "415",
    "description": "California",
  },
  {
    "code": "417",
    "description": "Missouri",
  },
  {
    "code": "419",
    "description": "Ohio",
  },
  {
    "code": "423",
    "description": "Tennessee",
  },
  {
    "code": "424",
    "description": "California",
  },
  {
    "code": "425",
    "description": "Washington",
  },
  {
    "code": "430",
    "description": "Texas",
  },
  {
    "code": "432",
    "description": "Texas",
  },
  {
    "code": "434",
    "description": "Virginia",
  },
  {
    "code": "435",
    "description": "Utah",
  },
  {
    "code": "440",
    "description": "Ohio",
  },
  {
    "code": "442",
    "description": "California",
  },
  {
    "code": "443",
    "description": "Maryland",
  },
  {
    "code": "464",
    "description": "Illinois",
  },
  {
    "code": "469",
    "description": "Texas",
  },
  {
    "code": "470",
    "description": "Georgia",
  },
  {
    "code": "475",
    "description": "Connecticut",
  },
  {
    "code": "478",
    "description": "Georgia",
  },
  {
    "code": "479",
    "description": "Arkansas",
  },
  {
    "code": "480",
    "description": "Arizona",
  },
  {
    "code": "484",
    "description": "Pennsylvania",
  },
  {
    "code": "501",
    "description": "Arkansas",
  },
  {
    "code": "502",
    "description": "Kentucky",
  },
  {
    "code": "503",
    "description": "Oregon",
  },
  {
    "code": "504",
    "description": "Louisiana",
  },
  {
    "code": "505",
    "description": "New Mexico",
  },
  {
    "code": "507",
    "description": "Minnesota",
  },
  {
    "code": "508",
    "description": "Massachusetts",
  },
  {
    "code": "509",
    "description": "Washington",
  },
  {
    "code": "510",
    "description": "California",
  },
  {
    "code": "512",
    "description": "Texas",
  },
  {
    "code": "513",
    "description": "Ohio",
  },
  {
    "code": "515",
    "description": "Iowa",
  },
  {
    "code": "516",
    "description": "New York",
  },
  {
    "code": "517",
    "description": "Michigan",
  },
  {
    "code": "518",
    "description": "New York",
  },
  {
    "code": "520",
    "description": "Arizona",
  },
  {
    "code": "530",
    "description": "California",
  },
  {
    "code": "539",
    "description": "Oklahoma",
  },
  {
    "code": "540",
    "description": "Virginia",
  },
  {
    "code": "541",
    "description": "Oregon",
  },
  {
    "code": "551",
    "description": "New Jersey",
  },
  {
    "code": "557",
    "description": "Missouri",
  },
  {
    "code": "559",
    "description": "California",
  },
  {
    "code": "561",
    "description": "Florida",
  },
  {
    "code": "562",
    "description": "California",
  },
  {
    "code": "563",
    "description": "Iowa",
  },
  {
    "code": "564",
    "description": "Washington",
  },
  {
    "code": "567",
    "description": "Ohio",
  },
  {
    "code": "570",
    "description": "Pennsylvania",
  },
  {
    "code": "571",
    "description": "Virginia",
  },
  {
    "code": "573",
    "description": "Missouri",
  },
  {
    "code": "574",
    "description": "Indiana",
  },
  {
    "code": "575",
    "description": "New Mexico",
  },
  {
    "code": "580",
    "description": "Oklahoma",
  },
  {
    "code": "585",
    "description": "New York",
  },
  {
    "code": "586",
    "description": "Michigan",
  },
  {
    "code": "601",
    "description": "Mississippi",
  },
  {
    "code": "602",
    "description": "Arizona",
  },
  {
    "code": "603",
    "description": "New Hampshire",
  },
  {
    "code": "605",
    "description": "South Dakota",
  },
  {
    "code": "606",
    "description": "Kentucky",
  },
  {
    "code": "607",
    "description": "New York",
  },
  {
    "code": "608",
    "description": "Wisconsin",
  },
  {
    "code": "609",
    "description": "New Jersey",
  },
  {
    "code": "610",
    "description": "Pennsylvania",
  },
  {
    "code": "612",
    "description": "Minnesota",
  },
  {
    "code": "614",
    "description": "Ohio",
  },
  {
    "code": "615",
    "description": "Tennessee",
  },
  {
    "code": "616",
    "description": "Michigan",
  },
  {
    "code": "617",
    "description": "Massachusetts",
  },
  {
    "code": "618",
    "description": "Illinois",
  },
  {
    "code": "619",
    "description": "California",
  },
  {
    "code": "620",
    "description": "Kansas",
  },
  {
    "code": "623",
    "description": "Arizona",
  },
  {
    "code": "626",
    "description": "California",
  },
  {
    "code": "627",
    "description": "California",
  },
  {
    "code": "628",
    "description": "California",
  },
  {
    "code": "630",
    "description": "Illinois",
  },
  {
    "code": "631",
    "description": "New York",
  },
  {
    "code": "636",
    "description": "Missouri",
  },
  {
    "code": "641",
    "description": "Iowa",
  },
  {
    "code": "646",
    "description": "New York",
  },
  {
    "code": "650",
    "description": "California",
  },
  {
    "code": "651",
    "description": "Minnesota",
  },
  {
    "code": "657",
    "description": "California",
  },
  {
    "code": "660",
    "description": "Missouri",
  },
  {
    "code": "661",
    "description": "California",
  },
  {
    "code": "662",
    "description": "Mississippi",
  },
  {
    "code": "669",
    "description": "California",
  },
  {
    "code": "670",
    "description": "Northern Mariana Islands",
  },
  {
    "code": "671",
    "description": "Guam",
  },
  {
    "code": "678",
    "description": "Georgia",
  },
  {
    "code": "679",
    "description": "Michigan",
  },
  {
    "code": "681",
    "description": "West Virginia",
  },
  {
    "code": "682",
    "description": "Texas",
  },
  {
    "code": "689",
    "description": "Florida",
  },
  {
    "code": "701",
    "description": "North Dakota",
  },
  {
    "code": "702",
    "description": "Nevada",
  },
  {
    "code": "703",
    "description": "Virginia",
  },
  {
    "code": "704",
    "description": "North Carolina",
  },
  {
    "code": "706",
    "description": "Georgia",
  },
  {
    "code": "707",
    "description": "California",
  },
  {
    "code": "708",
    "description": "Illinois",
  },
  {
    "code": "712",
    "description": "Iowa",
  },
  {
    "code": "713",
    "description": "Texas",
  },
  {
    "code": "714",
    "description": "California",
  },
  {
    "code": "715",
    "description": "Wisconsin",
  },
  {
    "code": "716",
    "description": "New York",
  },
  {
    "code": "717",
    "description": "Pennsylvania",
  },
  {
    "code": "718",
    "description": "New York",
  },
  {
    "code": "719",
    "description": "Colorado",
  },
  {
    "code": "720",
    "description": "Colorado",
  },
  {
    "code": "724",
    "description": "Pennsylvania",
  },
  {
    "code": "727",
    "description": "Florida",
  },
  {
    "code": "731",
    "description": "Tennessee",
  },
  {
    "code": "732",
    "description": "New Jersey",
  },
  {
    "code": "734",
    "description": "Michigan",
  },
  {
    "code": "737",
    "description": "Texas",
  },
  {
    "code": "740",
    "description": "Ohio",
  },
  {
    "code": "747",
    "description": "California",
  },
  {
    "code": "754",
    "description": "Florida",
  },
  {
    "code": "757",
    "description": "Virginia",
  },
  {
    "code": "760",
    "description": "California",
  },
  {
    "code": "762",
    "description": "Georgia",
  },
  {
    "code": "763",
    "description": "Minnesota",
  },
  {
    "code": "764",
    "description": "California",
  },
  {
    "code": "765",
    "description": "Indiana",
  },
  {
    "code": "769",
    "description": "Mississippi",
  },
  {
    "code": "770",
    "description": "Georgia",
  },
  {
    "code": "772",
    "description": "Florida",
  },
  {
    "code": "773",
    "description": "Illinois",
  },
  {
    "code": "774",
    "description": "Massachusetts",
  },
  {
    "code": "775",
    "description": "Nevada",
  },
  {
    "code": "779",
    "description": "Illinois",
  },
  {
    "code": "781",
    "description": "Massachusetts",
  },
  {
    "code": "785",
    "description": "Kansas",
  },
  {
    "code": "786",
    "description": "Florida",
  },
  {
    "code": "787",
    "description": "Puerto Rico",
  },
  {
    "code": "801",
    "description": "Utah",
  },
  {
    "code": "802",
    "description": "Vermont",
  },
  {
    "code": "803",
    "description": "South Carolina",
  },
  {
    "code": "804",
    "description": "Virginia",
  },
  {
    "code": "805",
    "description": "California",
  },
  {
    "code": "806",
    "description": "Texas",
  },
  {
    "code": "808",
    "description": "Hawaii",
  },
  {
    "code": "810",
    "description": "Michigan",
  },
  {
    "code": "812",
    "description": "Indiana",
  },
  {
    "code": "813",
    "description": "Florida",
  },
  {
    "code": "814",
    "description": "Pennsylvania",
  },
  {
    "code": "815",
    "description": "Illinois",
  },
  {
    "code": "816",
    "description": "Missouri",
  },
  {
    "code": "817",
    "description": "Texas",
  },
  {
    "code": "818",
    "description": "California",
  },
  {
    "code": "828",
    "description": "North Carolina",
  },
  {
    "code": "830",
    "description": "Texas",
  },
  {
    "code": "831",
    "description": "California",
  },
  {
    "code": "832",
    "description": "Texas",
  },
  {
    "code": "835",
    "description": "Pennsylvania",
  },
  {
    "code": "843",
    "description": "South Carolina",
  },
  {
    "code": "845",
    "description": "New York",
  },
  {
    "code": "847",
    "description": "Illinois",
  },
  {
    "code": "848",
    "description": "New Jersey",
  },
  {
    "code": "850",
    "description": "Florida",
  },
  {
    "code": "856",
    "description": "New Jersey",
  },
  {
    "code": "857",
    "description": "Massachusetts",
  },
  {
    "code": "858",
    "description": "California",
  },
  {
    "code": "859",
    "description": "Kentucky",
  },
  {
    "code": "860",
    "description": "Connecticut",
  },
  {
    "code": "862",
    "description": "New Jersey",
  },
  {
    "code": "863",
    "description": "Florida",
  },
  {
    "code": "864",
    "description": "South Carolina",
  },
  {
    "code": "865",
    "description": "Tennessee",
  },
  {
    "code": "870",
    "description": "Arkansas",
  },
  {
    "code": "872",
    "description": "Illinois",
  },
  {
    "code": "878",
    "description": "Pennsylvania",
  },
  {
    "code": "901",
    "description": "Tennessee",
  },
  {
    "code": "903",
    "description": "Texas",
  },
  {
    "code": "904",
    "description": "Florida",
  },
  {
    "code": "906",
    "description": "Michigan",
  },
  {
    "code": "907",
    "description": "Alaska",
  },
  {
    "code": "908",
    "description": "New Jersey",
  },
  {
    "code": "909",
    "description": "California",
  },
  {
    "code": "910",
    "description": "North Carolina",
  },
  {
    "code": "912",
    "description": "Georgia",
  },
  {
    "code": "913",
    "description": "Kansas",
  },
  {
    "code": "914",
    "description": "New York",
  },
  {
    "code": "915",
    "description": "Texas",
  },
  {
    "code": "916",
    "description": "California",
  },
  {
    "code": "917",
    "description": "New York",
  },
  {
    "code": "918",
    "description": "Oklahoma",
  },
  {
    "code": "919",
    "description": "North Carolina",
  },
  {
    "code": "920",
    "description": "Wisconsin",
  },
  {
    "code": "925",
    "description": "California",
  },
  {
    "code": "927",
    "description": "Florida",
  },
  {
    "code": "928",
    "description": "Arizona",
  },
  {
    "code": "931",
    "description": "Tennessee",
  },
  {
    "code": "935",
    "description": "California",
  },
  {
    "code": "936",
    "description": "Texas",
  },
  {
    "code": "937",
    "description": "Ohio",
  },
  {
    "code": "939",
    "description": "Puerto Rico",
  },
  {
    "code": "940",
    "description": "Texas",
  },
  {
    "code": "941",
    "description": "Florida",
  },
  {
    "code": "947",
    "description": "Michigan",
  },
  {
    "code": "949",
    "description": "California",
  },
  {
    "code": "951",
    "description": "California",
  },
  {
    "code": "952",
    "description": "Minnesota",
  },
  {
    "code": "954",
    "description": "Florida",
  },
  {
    "code": "956",
    "description": "Texas",
  },
  {
    "code": "957",
    "description": "New Mexico",
  },
  {
    "code": "959",
    "description": "Connecticut",
  },
  {
    "code": "970",
    "description": "Colorado",
  },
  {
    "code": "971",
    "description": "Oregon",
  },
  {
    "code": "972",
    "description": "Texas",
  },
  {
    "code": "973",
    "description": "New Jersey",
  },
  {
    "code": "975",
    "description": "Missouri",
  },
  {
    "code": "978",
    "description": "Massachusetts",
  },
  {
    "code": "979",
    "description": "Texas",
  },
  {
    "code": "980",
    "description": "North Carolina",
  },
  {
    "code": "984",
    "description": "North Carolina",
  },
  {
    "code": "985",
    "description": "Louisiana",
  },
  {
    "code": "989",
    "description": "Michigan",
  },
]
