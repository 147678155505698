var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(
          "Numbers available in " + _vm._s(_vm.selectedAreaCode.description)
        ),
      ]),
      _c(
        "div",
        { staticClass: "row mt-4" },
        _vm._l(_vm.availableNumbers, function (phoneNum, index) {
          return _c(
            "div",
            {
              key: `phoneNum-${index}`,
              staticClass: "col-lg-6 col-md-12 button-wrapper",
              on: {
                click: function ($event) {
                  return _vm.selectItem(phoneNum)
                },
              },
            },
            [
              _c(
                "b-form-radio",
                {
                  staticClass: "phone-number-radio-button",
                  attrs: {
                    size: "lg",
                    "aria-describedby": phoneNum,
                    name: "phoneNumSelectionRadio",
                    value: phoneNum,
                  },
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                },
                [
                  _c("div", { staticStyle: { "padding-left": "30px" } }, [
                    _c("span", {
                      directives: [
                        {
                          name: "phone-number",
                          rawName: "v-phone-number",
                          value: phoneNum,
                          expression: "phoneNum",
                        },
                      ],
                    }),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "b-row",
        { attrs: { "align-h": "end" } },
        [
          _c(
            "b-button",
            {
              staticClass: "previous-button mr-1",
              attrs: { variant: "info", size: "lg" },
              on: { click: _vm.resetModalFields },
            },
            [_vm._v("\n      Previous\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "next-button",
              attrs: { variant: "info", size: "lg", disabled: !_vm.selected },
              on: { click: _vm.storeSelection },
            },
            [_vm._v("\n      Next\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }