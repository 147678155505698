var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "virtual-phone-setup-modal",
        "hide-header": true,
        "hide-footer": true,
        "no-close-on-backdrop": "",
        "dialog-class": "virtual-phone-setup-dialog",
        "body-class": "phone-setup-modal-body",
        size: _vm.currentStep === "Step4" ? "xl" : "lg",
        "footer-class": "justify-content-center",
      },
    },
    [
      _vm._t("body", function () {
        return [_c(_vm.currentStep, { tag: "component" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }