<template>
  <div>
    <p>Phone Nickname</p>
    <p class="help-text">Enter a nickname for this phone number. The nickname will help you easily identify this number, especially if you have multiple numbers on your account. Choose a name that makes the purpose of this number clear, like 'Work Phone,' 'Rental Property,' or 'Professional Networking.'</p>
    <div class="phone-name-field">
      <b-input
        v-model="phoneName"
        placeholder="Ex: Bob's Cell"
        @keyup.enter="setName"
      />
    </div>
    <b-row align-h="end">
      <b-button
        class="next-button"
        variant="info"
        size="lg"
        :disabled="!phoneName"
        @click="setName"
      >
        Continue
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Step3',

  data () {
    return {
      phoneName: null,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', []),
  },

  methods: {
    ...mapActions('virtualPhones', ['setPhoneName']),

    setName() {
      if (this.phoneName.length > 0) {
        this.setPhoneName({name: this.phoneName, vm: this})
      }
    },
  },
}
</script>

<style lang="scss">

.virtual-phone-setup-dialog {
  margin-top: 150px;

  .phone-setup-modal-body {
    margin: 50px 60px 30px;
    font-size: 21px;
    line-height: 32px;
    color: #47464B;
  }

  .next-button {
    margin-right: 10px;
    background-color: #3EBDBB;
    border-color: #3EBDBB;
    height: 60px;
  }

  .phone-name-field {
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

.help-text {
  font-size: 0.938rem;
}


</style>
