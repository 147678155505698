var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", [_vm._v("Phone Nickname")]),
      _c("p", { staticClass: "help-text" }, [
        _vm._v(
          "Enter a nickname for this phone number. The nickname will help you easily identify this number, especially if you have multiple numbers on your account. Choose a name that makes the purpose of this number clear, like 'Work Phone,' 'Rental Property,' or 'Professional Networking.'"
        ),
      ]),
      _c(
        "div",
        { staticClass: "phone-name-field" },
        [
          _c("b-input", {
            attrs: { placeholder: "Ex: Bob's Cell" },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.setName.apply(null, arguments)
              },
            },
            model: {
              value: _vm.phoneName,
              callback: function ($$v) {
                _vm.phoneName = $$v
              },
              expression: "phoneName",
            },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        { attrs: { "align-h": "end" } },
        [
          _c(
            "b-button",
            {
              staticClass: "next-button",
              attrs: { variant: "info", size: "lg", disabled: !_vm.phoneName },
              on: { click: _vm.setName },
            },
            [_vm._v("\n      Continue\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }