<template>
  <multi-select
    :value="selectedJurisdiction"
    track-by="code"
    label="code"
    placeholder="Select a jurisdiction or area code"
    :custom-label="customLabel"
    :options="bandwidthAreaCodesSorted"
    :searchable="true"
    :allow-empty="false"
    @input="jurisdictionSelected"
    @search-change="search({ name: $event })"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      {{ customLabel(option) }}
    </template>
  </multi-select>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import bandwidthAreaCodes from '../common/modules/bandwidthAreaCodes'
import { sortBy } from 'lodash/collection'

export default {
  name: 'BandwidthJurisdictionSelect',

  components: {
    MultiSelect,
  },

  data () {
    return {
      bandwidthAreaCodes: bandwidthAreaCodes,
      selectedJurisdiction: null,
    }
  },

  computed: {
    bandwidthAreaCodesSorted() {
      return sortBy(bandwidthAreaCodes, 'description')
    },
  },

  methods: {
    jurisdictionSelected (value) {
      this.selectedJurisdiction = value
      this.$emit('input', value)
    },
    customLabel ({ code, description }) {
      return `${code} - ${description}`
    },
  },
}
</script>
