var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multi-select", {
    attrs: {
      value: _vm.selectedJurisdiction,
      "track-by": "code",
      label: "code",
      placeholder: "Select a jurisdiction or area code",
      "custom-label": _vm.customLabel,
      options: _vm.bandwidthAreaCodesSorted,
      searchable: true,
      "allow-empty": false,
    },
    on: {
      input: _vm.jurisdictionSelected,
      "search-change": function ($event) {
        return _vm.search({ name: $event })
      },
    },
    scopedSlots: _vm._u([
      {
        key: "singleLabel",
        fn: function ({ option }) {
          return [_vm._v("\n    " + _vm._s(_vm.customLabel(option)) + "\n  ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }