var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("mobile-access", {
        attrs: { "is-modal": true, "mobile-app-type": "virtual-phone" },
      }),
      _c(
        "b-row",
        { attrs: { "align-h": "end" } },
        [
          !_vm.modalSubmissionInProgress
            ? _c(
                "b-button",
                {
                  staticClass: "next-button",
                  attrs: {
                    "aria-label": "Finish button",
                    variant: "info",
                    size: "lg",
                  },
                  on: { click: _vm.finishSetup },
                },
                [_vm._v("\n      Finish\n    ")]
              )
            : _c("ct-centered-spinner"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }