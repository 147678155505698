<template>
  <div>
    <p>Numbers available in {{ selectedAreaCode.description }}</p>
    <div class="row mt-4">
      <div
        v-for="(phoneNum, index) in availableNumbers"
        :key="`phoneNum-${index}`"
        class="col-lg-6 col-md-12 button-wrapper"
        @click="selectItem(phoneNum)"
      >
        <b-form-radio
          v-model="selected"
          size="lg"
          class="phone-number-radio-button"
          :aria-describedby="phoneNum"
          name="phoneNumSelectionRadio"
          :value="phoneNum"
        >
          <div style="padding-left: 30px">
            <span v-phone-number="phoneNum" />
          </div>
        </b-form-radio>
      </div>
    </div>
    <b-row align-h="end">
      <b-button
        class="previous-button mr-1"
        variant="info"
        size="lg"
        @click="resetModalFields"
      >
        Previous
      </b-button>
      <b-button
        class="next-button"
        variant="info"
        size="lg"
        :disabled="!selected"
        @click="storeSelection"
      >
        Next
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'

export default {
  name: 'Step2',

  directives: {
    phoneNumber,
  },

  data () {
    return {
      selected: null,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', ['availableNumbersFinished', 'selectedAreaCode', 'availableNumbers']),
  },

  methods: {
    ...mapActions('virtualPhones', ['setSelectedAvailableNumber', 'resetModalFields', 'submitModalDecisions']),

    selectItem(selected) {
      this.selected = selected
    },

    async storeSelection() {
      await this.setSelectedAvailableNumber(this.selected)
      await this.submitModalDecisions()
    },
  },
}
</script>

<style lang="scss">

.virtual-phone-setup-dialog {
  margin-top: 150px;

  .phone-setup-modal-body {
    margin: 50px 60px 30px;
    font-size: 21px;
    line-height: 32px;
    color: #47464B;
  }

  .button-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .next-button {
    margin-right: 10px;
    background-color: #3EBDBB;
    border-color: #3EBDBB;
    height: 60px;
  }

  .phone-number-radio-button {
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    padding: 20px 20px 20px 40px;
    margin-bottom: 20px;

    .custom-control-label::before {
      margin-left: 15px;
      border: 1px solid #DBDBDB;
    }

    .custom-control-label::after {
      margin-left: 15px;
    }
  }
}


</style>
